import React, { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Grid,
	Snackbar,
	Alert,
} from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import JobsTable from './JobsTable';
import CustomerSelect from './CustomerSelect';
import {
	useStyles,
	fetchCustomerData,
	trainModel,
	ModelsTab,
} from './smartTopicsUtils';

function SmartTopics() {
	const classes = useStyles();
	const [searchText, setSearchText] = useState('');
	const [openTrainDialog, setOpenTrainDialog] = useState(false);
	const [selectedCustomerId, setSelectedCustomerId] = useState('');
	const [selectedCustomerName, setSelectedCustomerName] = useState('');
	const [objectType] = useState('CALL');
	const [topicType] = useState('SMART_TOPIC');
	const [jobVersion] = useState('v1');
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [customers, setCustomers] = useState([]);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [successResponse, setSuccessResponse] = useState(null);

	useEffect(() => {
		fetchCustomerData().then(setCustomers);
	}, []);

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
	};

	const handleTrainModelClick = () => {
		setOpenTrainDialog(true);
	};

	const handleCloseTrainDialog = () => {
		setOpenTrainDialog(false);
	};

	const handleTrainModel = async () => {
		if (!startTime || !endTime) {
			setSnackbarMessage('Start time and end time are required.');
			setSnackbarSeverity('error');
			setSnackbarOpen(true);
			return;
		}

		if (startTime >= endTime) {
			setSnackbarMessage('Start time must be less than end time.');
			setSnackbarSeverity('error');
			setSnackbarOpen(true);
			return;
		}

		const result = await trainModel(
			startTime,
			endTime,
			objectType,
			topicType,
			jobVersion,
			selectedCustomerId
		);
		setSnackbarMessage(result.message);
		setSnackbarSeverity(result.severity);
		setSuccessResponse(result.message);
		handleCloseTrainDialog();
		setSnackbarOpen(true);
	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	const handleCustomerSelect = (customerId, customerName) => {
		setSelectedCustomerId(customerId);
		setSelectedCustomerName(customerName);
		console.log(customerName);
	};

	return (
		<>
			{!selectedCustomerId ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						variant="h4"
						component="h1"
						color="primary"
						fontWeight="bold"
						mb={4}
					>
						Select a Customer
					</Typography>
					<CustomerSelect
						customers={customers}
						onSelect={handleCustomerSelect}
						width="300px"
					/>
				</Box>
			) : (
				<div className={classes.recorderExperiments}>
					<Box className={classes.content}>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							mb={6}
						>
							<Box display="flex" alignItems="center" mb={2}>
								<AnalyticsIcon
									sx={{
										fontSize: 40,
										color: 'primary.main',
										mr: 2,
									}}
								/>
								<Typography
									variant="h4"
									component="h4"
									color="primary"
									fontWeight="bold"
								>
									Topic Model Generation
								</Typography>
							</Box>
							<Typography
								variant="subtitle1"
								color="text.primary"
								align="center"
							>
								Jobs Analytics for Customer:{' '}
								<Typography
									component="span"
									variant="subtitle1"
									color="secondary"
									style={{ fontWeight: 'bold' }}
								>
									{selectedCustomerName}
								</Typography>
							</Typography>
							<Divider
								sx={{
									width: '80%',
									mt: 2,
									borderColor: 'primary.main',
								}}
							/>
						</Box>

						<TextField
							label="Search Jobs"
							variant="outlined"
							value={searchText}
							onChange={handleSearchChange}
							size="small"
							sx={{ width: '40%', mb: 3 }}
						/>
						<Button
							variant="contained"
							sx={{ ml: 3 }}
							onClick={handleTrainModelClick}
						>
							Train Model
						</Button>

						<JobsTable
							searchText={searchText}
							customerId={selectedCustomerId}
							successResponse={successResponse}
						/>
					</Box>
				</div>
			)}

			<Dialog
				open={openTrainDialog}
				onClose={handleCloseTrainDialog}
				maxWidth="lg"
				fullWidth
				PaperProps={{
					style: {
						height: '35%',
					},
				}}
			>
				<DialogTitle>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
						mb={3}
					>
						<Typography
							variant="h4"
							component="h4"
							color="primary"
							fontWeight="bold"
							textAlign="center"
						>
							Train Model for Customer: {selectedCustomerName}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Grid container justifyContent="center" spacing={3}>
							<Grid
								item
								xs={12}
								md={5}
								className={classes.dialogBox}
							>
								<Box
									display="flex"
									flexDirection="column"
									gap={2}
									width={'100%'}
								>
									<DateTimePicker
										renderInput={(props) => (
											<TextField {...props} />
										)}
										label="Start Time"
										value={startTime}
										onChange={(date) => setStartTime(date)}
										maxDate={endTime} 
									/>
									<DateTimePicker
										renderInput={(props) => (
											<TextField {...props} />
										)}
										label="End Time"
										value={endTime}
										onChange={(date) => setEndTime(date)}
										maxDate={new Date()} 
									/>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								md={5}
								className={classes.dialogBox}
							>
								<Box
									display="flex"
									flexDirection="column"
									gap={2}
								>
									<TextField
										label="Object Type"
										value={objectType}
										disabled
										fullWidth
									/>
									<TextField
										label="Topic Type"
										value={topicType}
										disabled
										fullWidth
									/>
									<TextField
										label="Job Version"
										value={jobVersion}
										disabled
										fullWidth
									/>
								</Box>
							</Grid>
						</Grid>
					</LocalizationProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTrainDialog}>Cancel</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleTrainModel}
					>
						Train Model
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					sx={{ width: '100%' }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
}

export default SmartTopics;